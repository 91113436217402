.dashboard h1 {
    margin: 0px;
    font-family: "Poppins";
    color: #828282;
    font-size: 30px;
}

.dashboard_cards {
    height: 30vh;
    display: flex;
    align-content: center;
    margin-top: 20px;
    /* flex-wrap: wrap; */
    /* justify-content: space-around; */
    gap: 20px;
}