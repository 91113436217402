.topbar {
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    /* height: 40px; */
    width: 79%;
    position: relative;
    left: 18%;

    transition: box-shadow 300ms 0ms;
    border-radius: 4px;
    margin: 20px 0px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.topbar h2 {
    margin: 0px;
    color: #828282;
    font-size: 25px;
    text-transform: uppercase;
}