 
._4tet {
    display: flex;
    align-items: center;
    width: 180px;
    border-radius: 6px;
    gap: 4px;
    background-color: rgba(198, 198, 198, 0.2);
    padding: 4px 12px 4px 4px;
    border: 1px solid #c6c6c6;
  }
  ._4tet .refold-pat {
    border: transparent;
    width: 200px;
    color: #5c6878;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: rgba(198, 198, 198, 0);
  }
  
  ._4tet .refold-pat:focus-visible {
    outline: none;
  }