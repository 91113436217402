.Card{
    width: 250px;
    height: 98px;
    padding: 20px;
    font-family: "Poppins";
    background-color: #E58638;
    /* color: #828282; */
    border-radius: 12px;
    cursor: pointer;
    transition: transform .2s;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    
}
.Card:hover,.Card:hover h2,.Card:hover h3{
    /* background-color: #e6a83c; */
    /* color: #ffffff; */
    transform: scale(1.1);
}

.Card h2{
    margin: 0px 0px 20px 0px;
    color: #ffffff;
    font-size: 20px;

}
.Card h3{
    color: #ffffff;
    margin: 0px;
    font-size: 20px;
}