.eclipses-jell {
    display: flex;
    align-items: center;
    border-right: 1px solid #d9d9dc;
}

.nuked-cob {
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

/* .rewarn-eye {
} */

.docile-tale {
    color: #c6c6c6;
  }

.tusked-porn {
    color: #c6c6c6;
}

/* .mavens-ben {
} */

.nettles-gigs {
    margin-right: 15px;
  }