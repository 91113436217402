.asideNavbar {
    width: 17%;
    margin: 0px;
    padding: 0px;
    position: fixed;
    top: 0%;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

}

.asideNavbar h1 {
    margin: 0px;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 25px;
    text-transform: uppercase;
    font-family: "Poppins";
    text-align: center;
    /* background: linear-gradient(90deg, rgba(169,234,166,1) 28%, rgba(90,188,20,1) 71%); */
    background: linear-gradient(90deg, rgb(16 95 12) 28%, rgba(90, 188, 20, 1) 71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.asideNavbarDiv {
    width: 100%;
    height: 100vh;
    /* background-color: #CEDFFF; */
    color: rgb(0, 0, 0);
    /* padding-top: 30px; */
    /* border-right: 5px solid rgb(57, 49, 49); */
}

#link {
    text-decoration: none;
}

.menu {
    text-align: left;
    padding: 12px;
    margin: 0px;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    background-color: #ffffff;
    color: #828282;
    text-decoration: none;

}

.logout {
    padding: 12px;
    cursor: pointer;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
    background-color: #ffffff;
    color: red;
    display: flex;
    gap: 10px;
    margin-top: 60px;
}

.asideNavbarDiv .menu:hover {
    background-color: #E58638;
    color: #fff;
    border-radius: 8px;
    width: 80%;
    align-items: center;
    margin-left: 15px;
    /* border-left: 5px solid black; */
}

.asideNavbarDiv .activeRoute {
    background-color: #E58638;
    color: #fff;
    border-radius: 8px;
    width: 80%;
    align-items: center;
    margin-left: 15px;
    /* border-left: 5px solid black; */
}

.outline_style {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    padding: 20px;
    /* margin-top: 10px; */
}