/* Container for all the cards (4 cards per row) */
.new-arrivals-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
    padding: 20px;
}

/* Each card's main wrapper (horizontal layout) */
.message-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    transition: transform 0.3s ease;
    position: relative; /* So that the button can be positioned correctly */
    justify-content: space-between;

}

/* Profile image or header image at the top (similar to WhatsApp image bubble) */
.header-image {
    width: 100%;
    height: 120px;
    /* overflow: hidden; */
    border-radius: 8px;
    margin-bottom: 10px;
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* Body section where the text is placed (message bubble effect like WhatsApp messages) */
.message-body {
    background-color: #e5e5e5;
    padding: 12px;
    border-radius: 8px;
    width: 86%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-bottom: 10px;
}

/* Text style inside the message bubble */
.message-text {
    font-size: 14px;
    color: #333;
    white-space: pre-wrap; /* Allow multiline text */
}

/* Hover effect to scale the card slightly */
.message-card:hover {
    transform: scale(1.05);
}

/* Edit Button */
.edit-button {
    /* position: absolute;
    bottom: 10px;
    left: 50%; */
    /* transform: translateX(-50%); */
    background-color: #E58638; /* Button color */
    color: white;
    font-size: 14px;
    padding: 10px 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100px;
}

.edit-button:hover {
    background-color: #d7752f; /* Slightly darker shade on hover */
}

/* Responsive: Stack vertically on smaller screens */
@media (max-width: 768px) {
    .new-arrivals-container {
        flex-direction: column;
        align-items: center;
    }
}

/* Custom styles for the upload image button */
#image-upload {
    display: none;
}

label[for="image-upload"] .MuiButton-root {
    background-color: #E58638;  /* Custom background color for "Upload Image" button */
    color: white;  /* White text */
}

label[for="image-upload"] .MuiButton-root:hover {
    background-color: #d9742d;  /* Darker shade on hover */
}

/* Style for preview image */
.dialog-image-preview {
    margin-top: 10px;
}

.dialog-image-preview img {
    width: 220px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
}

/* Style for the Submit and Cancel Buttons with green and red background */
.MuiDialogActions-root .MuiButton-root {
    font-weight: bold;
    text-transform: none;
}

.MuiDialogActions-root .MuiButton-root.MuiButton-containedPrimary {
    background-color: #4CAF50;  /* Green background for Submit */
    color: white;  /* White text */
    &:hover {
        background-color: #388e3c;  /* Darker green on hover */
    }
}

.MuiDialogActions-root .MuiButton-root.MuiButton-containedSecondary {
    background-color: #f44336;  /* Red background for Cancel */
    color: white;  /* White text */
    &:hover {
        background-color: #d32f2f;  /* Darker red on hover */
    }
}
